var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Tabs",
    { staticClass: "tabs", attrs: { type: "card" } },
    _vm._l(_vm.data, function (item, index) {
      return _c(
        "TabPane",
        {
          key: index,
          attrs: { lazy: true, label: item.name, name: item.name },
        },
        [_c("Java", { attrs: { value: item.content, height: _vm.height } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }